import { Game } from "@/interfaces/game";

export const GAMES: Game[] = [
    {
        "text": "is final fantasy 16",
        "answer": "online",
        "luckyGuess": "coming"
    },
    {
        "text": "will chatGPT become",
        "answer": "illegal",
        "luckyGuess": "smarter"
    },
    {
        "text": "will australia ever be",
        "answer": "invaded",
        "luckyGuess": "flooded"
    },
    {
        "text": "is human or not",
        "answer": "safe",
        "luckyGuess": "real"
    },
    {
        "text": "will i die if i eat",
        "answer": "mold",
        "luckyGuess": "glue"
    },
    {
        "text": "is human skin",
        "answer": "leather",
        "luckyGuess": "tougher"
    },
    {
        "text": "why is mario so",
        "answer": "short",
        "luckyGuess": "quick"
    },
    {
        "text": "why is mr. beast",
        "answer": "famous",
        "luckyGuess": "giving"
    },
    {
        "text": "why are americans so",
        "answer": "tall",
        "luckyGuess": "bold"
    },
    {
        "text": "what happens if I drink too much",
        "answer": "coffee",
        "luckyGuess": "liquor"
    },
    {
        "text": "can I get addicted to",
        "answer": "vape",
        "luckyGuess": "wine"
    },
    {
        "text": "do women like",
        "answer": "beards",
        "luckyGuess": "flirts"
    },
    {
        "text": "can you get blood from a",
        "answer": "turnip",
        "luckyGuess": "potato"
    },
    {
        "text": "why can't i own a",
        "answer": "tank",
        "luckyGuess": "nuke"
    },
    {
        "text": "are muslims allowed to",
        "answer": "swear",
        "luckyGuess": "drink"
    },
    {
        "text": "can i use string to",
        "answer": "floss",
        "luckyGuess": "braid"
    },
    {
        "text": "i like to pretend that i'm a",
        "answer": "carrot",
        "luckyGuess": "rabbit"
    },
    {
        "text": "is zelda a",
        "answer": "dragon",
        "luckyGuess": "prince"
    },
    {
        "text": "will ai destroy",
        "answer": "humanity",
        "luckyGuess": "industry"
    },
    {
        "text": "crypto made me",
        "answer": "broke",
        "luckyGuess": "smart"
    },
    {
        "text": "can a tesla be",
        "answer": "hacked",
        "luckyGuess": "stolen"
    },
    {
        "text": "my tiktok is in",
        "answer": "spanish",
        "luckyGuess": "russian"
    },
    {
        "text": "my life is",
        "answer": "potato",
        "luckyGuess": "boring"
    },
    {
        "text": "is mark zuckerberg a",
        "answer": "cyborg",
        "luckyGuess": "lizard"
    },
    {
        "text": "can donkeys",
        "answer": "laugh",
        "luckyGuess": "speak"
    },
    {
        "text": "is giga chad",
        "answer": "alive",
        "luckyGuess": "happy"
    },
    {
        "text": "why does nintendo hate",
        "answer": "luigi",
        "luckyGuess": "wario"
    },
    {
        "text": "why don't men",
        "answer": "listen",
        "luckyGuess": "change"
    },
    {
        "text": "when i jump i",
        "answer": "pee",
        "luckyGuess": "fly"
    },
    {
        "text": "what if google was a",
        "answer": "human",
        "luckyGuess": "robot"
    },
    {
        "text": "why is the world so",
        "answer": "cruel",
        "luckyGuess": "harsh"
    },
    {
        "text": "did facebook buy",
        "answer": "tiktok",
        "luckyGuess": "oculus"
    },
    {
        "text": "oh no i dropped my",
        "answer": "prime",
        "luckyGuess": "phone"
    },
    {
        "text": "do masks cause",
        "answer": "wrinkles",
        "luckyGuess": "headache"
    },
    {
        "text": "my mother is so",
        "answer": "annoying",
        "luckyGuess": "negative"
    },
    {
        "text": "tiktok is",
        "answer": "cringe",
        "luckyGuess": "addict"
    },
    {
        "text": "did elon make twitter",
        "answer": "worse",
        "luckyGuess": "money"
    },
    {
        "text": "can humans get",
        "answer": "fleas",
        "luckyGuess": "wings"
    },
    {
        "text": "i broke my",
        "answer": "phone",
        "luckyGuess": "heart"
    },
    {
        "text": "do mushrooms make you",
        "answer": "taller",
        "luckyGuess": "sicker"
    },
    {
        "text": "is it normal to have",
        "answer": "dandruff",
        "luckyGuess": "feathers"
    },
    {
        "text": "never put a sock in a",
        "answer": "toaster",
        "luckyGuess": "blender"
    },
    {
        "text": "does will smith have an",
        "answer": "oscar",
        "luckyGuess": "child"
    },
    {
        "text": "what happens if i eat too much",
        "answer": "protein",
        "luckyGuess": "noodles"
    },
    {
        "text": "why is wordle so",
        "answer": "popular",
        "luckyGuess": "complex"
    },
    {
        "text": "is bing a",
        "answer": "virus",
        "luckyGuess": "guide"
    },
    {
        "text": "are canadians",
        "answer": "american",
        "luckyGuess": "friendly"
    },
    {
        "text": "why is netflix so",
        "answer": "laggy",
        "luckyGuess": "quiet"
    },
    {
        "text": "why are the kardashians so",
        "answer": "dramatic",
        "luckyGuess": "annoying"
    },
    {
        "text": "fifa is",
        "answer": "rigged",
        "luckyGuess": "ending"
    },
    {
        "text": "my dog is so",
        "answer": "needy",
        "luckyGuess": "happy"
    },
    {
        "text": "will i die if i drink",
        "answer": "shampoo",
        "luckyGuess": "whiskey"
    },
    {
        "text": "why do cats",
        "answer": "meow",
        "luckyGuess": "purr"
    },
    {
        "text": "is solver tom",
        "answer": "human",
        "luckyGuess": "there"
    },
    {
        "text": "do fish ever",
        "answer": "drown",
        "luckyGuess": "sleep"
    },
    {
        "text": "would you please give me",
        "answer": "advice",
        "luckyGuess": "credit"
    },
    {
        "text": "fastest way to get into",
        "answer": "ketosis",
        "luckyGuess": "fitness"
    },
    {
        "text": "what to do when",
        "answer": "angry",
        "luckyGuess": "awake"
    },
    {
        "text": "how to fix a",
        "answer": "zipper",
        "luckyGuess": "button"
    },
    {
        "text": "tips for growing",
        "answer": "plants",
        "luckyGuess": "beards"
    },
    {
        "text": "how to write a",
        "answer": "resume",
        "luckyGuess": "report"
    },
    {
        "text": "effective methods for",
        "answer": "study",
        "luckyGuess": "focus"
    },
    {
        "text": "does bill gates own a",
        "answer": "plane",
        "luckyGuess": "yacht"
    },
    {
        "text": "can humans live without",
        "answer": "bees",
        "luckyGuess": "meat"
    },
    {
        "text": "did harry potter ever",
        "answer": "cry",
        "luckyGuess": "die"
    },
    {
        "text": "is it normal for a dog to",
        "answer": "snore",
        "luckyGuess": "vomit"
    },
    {
        "text": "is it true that vegans eat",
        "answer": "fish",
        "luckyGuess": "meat"
    },
    {
        "text": "why are spiders so",
        "answer": "smart",
        "luckyGuess": "scary"
    },
    {
        "text": "what happens when you cross a",
        "answer": "border",
        "luckyGuess": "pisces"
    },
    {
        "text": "can babies see",
        "answer": "angels",
        "luckyGuess": "colour"
    },
    {
        "text": "is it weird to love",
        "answer": "school",
        "luckyGuess": "animal"
    },
    {
        "text": "my cat acts like a",
        "answer": "child",
        "luckyGuess": "bunny"
    },
    {
        "text": "does the internet make you",
        "answer": "smarter",
        "luckyGuess": "quicker"
    },
    {
        "text": "is jeff bezos",
        "answer": "alive",
        "luckyGuess": "greek"
    },
    {
        "text": "why do parents",
        "answer": "fight",
        "luckyGuess": "argue"
    },
    {
        "text": "is it bad to eat before",
        "answer": "gym",
        "luckyGuess": "bed"
    },
    {
        "text": "when will humans",
        "answer": "evolve",
        "luckyGuess": "return"
    },
    {
        "text": "is it legal to keep a pet",
        "answer": "otter",
        "luckyGuess": "eagle"
    },
    {
        "text": "do birds ever",
        "answer": "sleep",
        "luckyGuess": "fight"
    },
    {
        "text": "why does bitcoin",
        "answer": "exist",
        "luckyGuess": "halve"
    },
    {
        "text": "is it wrong to not like",
        "answer": "babies",
        "luckyGuess": "family"
    },
    {
        "text": "my partner thinks I'm",
        "answer": "boring",
        "luckyGuess": "stupid"
    },
    {
        "text": "why are cars so",
        "answer": "cheap",
        "luckyGuess": "quiet"
    },
    {
        "text": "is it safe to",
        "answer": "travel",
        "luckyGuess": "invest"
    },
    {
        "text": "why does cheese have",
        "answer": "holes",
        "luckyGuess": "sweat"
    },
    {
        "text": "does sleep really help you",
        "answer": "grow",
        "luckyGuess": "heal"
    },
    {
        "text": "are plants actually",
        "answer": "alive",
        "luckyGuess": "green"
    },
    {
        "text": "do fish ever get",
        "answer": "tired",
        "luckyGuess": "bored"
    },
    {
        "text": "are rainbows",
        "answer": "round",
        "luckyGuess": "light"
    },
    {
        "text": "is it okay to eat raw",
        "answer": "bacon",
        "luckyGuess": "pasta"
    },
    {
        "text": "why is the sky so",
        "answer": "high",
        "luckyGuess": "hazy"
    },
    {
        "text": "is it possible to run out of",
        "answer": "tears",
        "luckyGuess": "water"
    },
    {
        "text": "why are dogs so",
        "answer": "loyal",
        "luckyGuess": "happy"
    },
    {
        "text": "what happens if we run out of",
        "answer": "money",
        "luckyGuess": "water"
    },
    {
        "text": "is it possible to live on",
        "answer": "mars",
        "luckyGuess": "moon"
    },
    {
        "text": "why can't humans live",
        "answer": "longer",
        "luckyGuess": "deeper"
    },
    {
        "text": "are video games bad for",
        "answer": "eyes",
        "luckyGuess": "kids"
    },
    {
        "text": "why is space so",
        "answer": "empty",
        "luckyGuess": "scary"
    },
    {
        "text": "why are planets",
        "answer": "round",
        "luckyGuess": "quiet"
    },
    {
        "text": "why is water so",
        "answer": "heavy",
        "luckyGuess": "tasty"
    },
    {
        "text": "why are oceans",
        "answer": "salty",
        "luckyGuess": "empty"
    },
    {
        "text": "can the government control the",
        "answer": "weather",
        "luckyGuess": "dollar"
    },
    {
        "text": "why is chocolate so",
        "answer": "sweet",
        "luckyGuess": "yummy"
    },
    {
        "text": "is it possible for humans to have",
        "answer": "wings",
        "luckyGuess": "gills"
    },
    {
        "text": "are aliens",
        "answer": "coming",
        "luckyGuess": "demons"
    },
    {
        "text": "why are polar bears so",
        "answer": "deadly",
        "luckyGuess": "strong"
    },
    {
        "text": "is the world",
        "answer": "flat",
        "luckyGuess": "tiny"
    },
    {
        "text": "is it illegal to drive in",
        "answer": "heels",
        "luckyGuess": "socks"
    },
    {
        "text": "do trees feel",
        "answer": "emotion",
        "luckyGuess": "sadness"
    },
    {
        "text": "can an ant lift an",
        "answer": "apple",
        "luckyGuess": "human"
    },
    {
        "text": "why are we addicted to",
        "answer": "sugar",
        "luckyGuess": "phone"
    },
    {
        "text": "can we live without a",
        "answer": "brain",
        "luckyGuess": "liver"
    },
    {
        "text": "do robots have",
        "answer": "feelings",
        "luckyGuess": "emotions"
    },
    {
        "text": "why is honey so",
        "answer": "sticky",
        "luckyGuess": "useful"
    },
    {
        "text": "can i use my phone while",
        "answer": "charging",
        "luckyGuess": "updating"
    },
    {
        "text": "is the moon really",
        "answer": "cheese",
        "luckyGuess": "planet"
    },
    {
        "text": "is there life on",
        "answer": "venus",
        "luckyGuess": "plant"
    },
    {
        "text": "why are cats so",
        "answer": "clean",
        "luckyGuess": "funny"
    },
    {
        "text": "why do humans have",
        "answer": "chins",
        "luckyGuess": "nails"
    },
    {
        "text": "does the earth really",
        "answer": "rotate",
        "luckyGuess": "wobble"
    },
    {
        "text": "can a cat and dog",
        "answer": "breed",
        "luckyGuess": "dance"
    },
    {
        "text": "is it possible to buy a",
        "answer": "country",
        "luckyGuess": "rockets"
    },
    {
        "text": "why do humans need",
        "answer": "oxygen",
        "luckyGuess": "energy"
    },
    {
        "text": "are humans the only animals with",
        "answer": "thumbs",
        "luckyGuess": "rights"
    },
    {
        "text": "does music really calm",
        "answer": "anxiety",
        "luckyGuess": "emotion"
    },
    {
        "text": "why do people believe in",
        "answer": "aliens",
        "luckyGuess": "angels"
    },
    {
        "text": "is there a cure for",
        "answer": "cancer",
        "luckyGuess": "rabies"
    },
    {
        "text": "do you really need a",
        "answer": "snorkel",
        "luckyGuess": "wetsuit"
    },
    {
        "text": "do ghosts have",
        "answer": "teeth",
        "luckyGuess": "souls"
    },
    {
        "text": "whats the science behind",
        "answer": "farming",
        "luckyGuess": "cycling"
    },
    {
        "text": "how hard is it to become a",
        "answer": "pilot",
        "luckyGuess": "actor"
    },
    {
        "text": "why are there",
        "answer": "seasons",
        "luckyGuess": "planets"
    },
    {
        "text": "what happens when you",
        "answer": "dream",
        "luckyGuess": "sleep"
    },
    {
        "text": "how to deal with",
        "answer": "stress",
        "luckyGuess": "people"
    },
    {
        "text": "the importance of having",
        "answer": "friends",
        "luckyGuess": "savings"
    },
    {
        "text": "do penguins ever have",
        "answer": "twins",
        "luckyGuess": "colds"
    },
    {
        "text": "what happens if you give a mouse a",
        "answer": "cookie",
        "luckyGuess": "cheese"
    },
    {
        "text": "is it possible to clone a",
        "answer": "human",
        "luckyGuess": "mouse"
    },
    {
        "text": "why do cannibals wear",
        "answer": "masks",
        "luckyGuess": "pants"
    },
    {
        "text": "how to start a",
        "answer": "essay",
        "luckyGuess": "story"
    },
    {
        "text": "is it painful to give birth to a",
        "answer": "dog",
        "luckyGuess": "cat"
    },
    {
        "text": "why do serial killers have",
        "answer": "fans",
        "luckyGuess": "eyes"
    },
    {
        "text": "can you sell your",
        "answer": "blood",
        "luckyGuess": "brain"
    },
    {
        "text": "can zombies",
        "answer": "swim",
        "luckyGuess": "jump"
    },
    {
        "text": "is it legal to eat a",
        "answer": "turtle",
        "luckyGuess": "person"
    },
    {
        "text": "can you create a black hole with a",
        "answer": "photo",
        "luckyGuess": "horse"
    },
    {
        "text": "what is the most horrible",
        "answer": "smell",
        "luckyGuess": "sight"
    },
    {
        "text": "how to escape from",
        "answer": "school",
        "luckyGuess": "myself"
    },
    {
        "text": "can you control your",
        "answer": "dreams",
        "luckyGuess": "friend"
    },
    {
        "text": "is there a secret",
        "answer": "emoji",
        "luckyGuess": "world"
    },
    {
        "text": "why does it hurt when i",
        "answer": "breathe",
        "luckyGuess": "swallow"
    },
    {
        "text": "can you marry your",
        "answer": "cousin",
        "luckyGuess": "sister"
    },
    {
        "text": "exploding",
        "answer": "watermelon",
        "luckyGuess": "windjammer"
    },
    {
        "text": "can you survive a fall from a",
        "answer": "plane",
        "luckyGuess": "cliff"
    },
    {
        "text": "is there a god of",
        "answer": "time",
        "luckyGuess": "fire"
    },
    {
        "text": "is it legal to kill a",
        "answer": "bear",
        "luckyGuess": "duck"
    },
    {
        "text": "can you drink your own",
        "answer": "blood",
        "luckyGuess": "sweat"
    },
    {
        "text": "stupid",
        "answer": "jokes",
        "luckyGuess": "memes"
    },
    {
        "text": "why do ghosts say",
        "answer": "boo",
        "luckyGuess": "baa"
    },
    {
        "text": "is it safe to kiss a",
        "answer": "frog",
        "luckyGuess": "baby"
    },
    {
        "text": "do cows have",
        "answer": "horns",
        "luckyGuess": "taste"
    },
    {
        "text": "can you fly with a",
        "answer": "hernia",
        "luckyGuess": "injury"
    },
    {
        "text": "is it scary to give",
        "answer": "birth",
        "luckyGuess": "blood"
    },
    {
        "text": "how to fake",
        "answer": "tan",
        "luckyGuess": "cry"
    },
    {
        "text": "is it fun to be a",
        "answer": "girl",
        "luckyGuess": "pony"
    },
    {
        "text": "why do mushrooms",
        "answer": "glow",
        "luckyGuess": "grow"
    },
    {
        "text": "can you see your own",
        "answer": "aura",
        "luckyGuess": "nose"
    },
    {
        "text": "is there a world record for",
        "answer": "sleeping",
        "luckyGuess": "twerking"
    },
    {
        "text": "best city for",
        "answer": "living",
        "luckyGuess": "expats"
    },
    {
        "text": "is it wise to trust a",
        "answer": "stranger",
        "luckyGuess": "computer",
    },
    {
        "text": "tips for keeping a pet",
        "answer": "snail",
        "luckyGuess": "mouse"
    },
    {
        "text": "can you smoke",
        "answer": "oregano",
        "luckyGuess": "potatos"
    },
    {
        "text": "funny",
        "answer": "socks",
        "luckyGuess": "jokes"
    },
    {
        "text": "how to trick a",
        "answer": "scammer",
        "luckyGuess": "spammer"
    },
    {
        "text": "is it okay to date your",
        "answer": "boss",
        "luckyGuess": "wife"
    },
    {
        "text": "i'm allergic to",
        "answer": "water",
        "luckyGuess": "dairy"
    },
    {
        "text": "is it safe to eat raw",
        "answer": "bacon",
        "luckyGuess": "steak"
    },
    {
        "text": "can you make money from",
        "answer": "youtube",
        "luckyGuess": "spotify"
    },
    {
        "text": "do stars ever",
        "answer": "explode",
        "luckyGuess": "twinkle"
    },
    {
        "text": "can santa bring",
        "answer": "pets",
        "luckyGuess": "dogs"
    },
    {
        "text": "dumb christmas",
        "answer": "gifts",
        "luckyGuess": "songs"
    },
    {
        "text": "why do reindeer have",
        "answer": "antlers",
        "luckyGuess": "goodies"
    },
    {
        "text": "does santa visit",
        "answer": "heaven",
        "luckyGuess": "adults"
    },
    {
        "text": "best christmas gift for my",
        "answer": "boss",
        "luckyGuess": "wife"
    },
    {
        "text": "do santas elves get",
        "answer": "paid",
        "luckyGuess": "sick"
    },
    {
        "text": "best christmas",
        "answer": "movies",
        "luckyGuess": "carols"
    },
    {
        "text": "does santa have an",
        "answer": "email",
        "luckyGuess": "phone"
    },
    {
        "text": "how to draw a",
        "answer": "dragon",
        "luckyGuess": "donkey"
    },
    {
        "text": "can you catch a",
        "answer": "bullet",
        "luckyGuess": "rabbit"
    },
    {
        "text": "can you physically buy",
        "answer": "gold",
        "luckyGuess": "meat"
    },
    {
        "text": "is it rude to",
        "answer": "point",
        "luckyGuess": "stare"
    },
    {
        "text": "can sleep make you",
        "answer": "taller",
        "luckyGuess": "sleepy"
    },
    {
        "text": "can you microwave a",
        "answer": "steak",
        "luckyGuess": "bacon"
    },
    {
        "text": "how to get rid of a",
        "answer": "cold",
        "luckyGuess": "sore"
    },
    {
        "text": "why do cats hate",
        "answer": "baths",
        "luckyGuess": "water"
    },
    {
        "text": "is it legal to marry a",
        "answer": "dog",
        "luckyGuess": "cat"
    },
    {
        "text": "how to make a",
        "answer": "website",
        "luckyGuess": "resumes"
    },
    {
        "text": "why does my dog eat",
        "answer": "grass",
        "luckyGuess": "rocks"
    },
    {
        "text": "can you smoke a",
        "answer": "turkey",
        "luckyGuess": "wombat"
    },
    {
        "text": "what is the meaning of",
        "answer": "life",
        "luckyGuess": "time"
    },
    {
        "text": "how to break up with a",
        "answer": "friend",
        "luckyGuess": "client"
    },
    {
        "text": "why do i dream about",
        "answer": "falling",
        "luckyGuess": "toilets"
    },
    {
        "text": "is it safe to drink",
        "answer": "urine",
        "luckyGuess": "water"
    },
    {
        "text": "can you train a",
        "answer": "ferret",
        "luckyGuess": "animal"
    },
    {
        "text": "what is the best way to",
        "answer": "study",
        "luckyGuess": "money"
    },
    {
        "text": "how to survive a",
        "answer": "tsunami",
        "luckyGuess": "tornado"
    },
    {
        "text": "why do some people have a",
        "answer": "lisp",
        "luckyGuess": "hair"
    },
    {
        "text": "can you freeze",
        "answer": "cheese",
        "luckyGuess": "celery"
    },
    {
        "text": "how to cure a",
        "answer": "hangover",
        "luckyGuess": "headache"
    },
    {
        "text": "can you pretend to be",
        "answer": "blind",
        "luckyGuess": "happy"
    },
    {
        "text": "where do i find",
        "answer": "love",
        "luckyGuess": "meat"
    },
    {
        "text": "why is my pee",
        "answer": "green",
        "luckyGuess": "clear"
    },
    {
        "text": "how to grow a",
        "answer": "beard",
        "luckyGuess": "plant"
    },
    {
        "text": "hidden places to get a",
        "answer": "tattoo",
        "luckyGuess": "affair"
    },
    {
        "text": "how to cheat on a",
        "answer": "exam",
        "luckyGuess": "test"
    },
    {
        "text": "why do babies",
        "answer": "stare",
        "luckyGuess": "sleep"
    },
    {
        "text": "can you die from being",
        "answer": "tickled",
        "luckyGuess": "wounded"
    },
    {
        "text": "how to tell if someone is",
        "answer": "drunk",
        "luckyGuess": "angry"
    },
    {
        "text": "what is the most dangerous",
        "answer": "spider",
        "luckyGuess": "suburb"
    },
    {
        "text": "how to become a",
        "answer": "lawyer",
        "luckyGuess": "doctor"
    },
    {
        "text": "how to hack a",
        "answer": "hacker",
        "luckyGuess": "server"
    },
    {
        "text": "what is the funniest",
        "answer": "movie",
        "luckyGuess": "thing"
    },
    {
        "text": "how to win a",
        "answer": "fight",
        "luckyGuess": "prize"
    },
    {
        "text": "why do men have",
        "answer": "nipples",
        "luckyGuess": "eyelash"
    },
    {
        "text": "can you fly after",
        "answer": "botox",
        "luckyGuess": "diver"
    },
    {
        "text": "how to hide a",
        "answer": "hickey",
        "luckyGuess": "gadget"
    },
    {
        "text": "what is the most annoying",
        "answer": "song",
        "luckyGuess": "word"
    },
    {
        "text": "how to kiss at a",
        "answer": "wedding",
        "luckyGuess": "funeral"
    },
    {
        "text": "is it wrong to wear a",
        "answer": "cross",
        "luckyGuess": "dress"
    },
    {
        "text": "how to build a",
        "answer": "website",
        "luckyGuess": "friends"
    },
    {
        "text": "what is the craziest",
        "answer": "zodiac",
        "luckyGuess": "animal"
    },
    {
        "text": "why am i losing so much",
        "answer": "muscle",
        "luckyGuess": "friend"
    },
    {
        "text": "why do women have",
        "answer": "breasts",
        "luckyGuess": "periods"
    },
    {
        "text": "why does tennis use",
        "answer": "love",
        "luckyGuess": "ball"
    },
    {
        "text": "can you catch",
        "answer": "rabies",
        "luckyGuess": "zombie"
    },
    {
        "text": "how long until",
        "answer": "easter",
        "luckyGuess": "sunday"
    },
    {
        "text": "how to stop a",
        "answer": "nosebleed",
        "luckyGuess": "hijackers"
    },
    {
        "text": "why are computer screens",
        "answer": "curved",
        "luckyGuess": "square"
    },
    {
        "text": "can humans digest",
        "answer": "grass",
        "luckyGuess": "metal"
    },
    {
        "text": "is it safe to swim with",
        "answer": "whales",
        "luckyGuess": "sharks"
    },
    {
        "text": "why do we",
        "answer": "blink",
        "luckyGuess": "dream"
    },
    {
        "text": "how to fix a broken",
        "answer": "heart",
        "luckyGuess": "phone"
    },
    {
        "text": "can plants feel",
        "answer": "pain",
        "luckyGuess": "cold"
    },
    {
        "text": "why does the moon appear",
        "answer": "white",
        "luckyGuess": "small"
    },
    {
        "text": "is it possible to see",
        "answer": "sound",
        "luckyGuess": "santa"
    },
    {
        "text": "do all birds have",
        "answer": "beaks",
        "luckyGuess": "wings"
    },
    {
        "text": "why are some people",
        "answer": "tall",
        "luckyGuess": "mean"
    },
    {
        "text": "should i eat before a",
        "answer": "tattoo",
        "luckyGuess": "saunas"
    },
    {
        "text": "how do animals know when to",
        "answer": "migrate",
        "luckyGuess": "evolves"
    },
    {
        "text": "why is the ocean",
        "answer": "foamy",
        "luckyGuess": "salty"
    },
    {
        "text": "can you live without a",
        "answer": "spleen",
        "luckyGuess": "kidney"
    },
    {
        "text": "why do we get goosebumps when",
        "answer": "touched",
        "luckyGuess": "excited"
    },
    {
        "text": "is it possible to change your",
        "answer": "voice",
        "luckyGuess": "apple"
    },
    {
        "text": "do plants need sunlight or",
        "answer": "heat",
        "luckyGuess": "rain"
    },
    {
        "text": "can you hear sounds in",
        "answer": "space",
        "luckyGuess": "water"
    },
    {
        "text": "is it dangerous to fly during a",
        "answer": "storm",
        "luckyGuess": "night"
    },
    {
        "text": "can i put socks in the",
        "answer": "dryer",
        "luckyGuess": "sauna"
    },
    {
        "text": "why do i cry when i see",
        "answer": "babies",
        "luckyGuess": "things"
    },
    {
        "text": "can computer screens cause",
        "answer": "vertigo",
        "luckyGuess": "anxiety"
    },
    {
        "text": "is it safe to drink",
        "answer": "coffee",
        "luckyGuess": "liquor"
    },
    {
        "text": "why are some fruits",
        "answer": "acidic",
        "luckyGuess": "bitter"
    },
    {
        "text": "can you see things from",
        "answer": "space",
        "luckyGuess": "earth"
    },
    {
        "text": "why are humans",
        "answer": "ticklish",
        "luckyGuess": "emotions"
    },
    {
        "text": "is it normal for adults to",
        "answer": "nap",
        "luckyGuess": "cry"
    },
    {
        "text": "can you train your brain to",
        "answer": "forget",
        "luckyGuess": "faster"
    },
    {
        "text": "how do predators sense",
        "answer": "fear",
        "luckyGuess": "weak"
    },
    {
        "text": "why is it important to recycle",
        "answer": "glass",
        "luckyGuess": "paper"
    },
    {
        "text": "can writing help with",
        "answer": "stress",
        "luckyGuess": "memory"
    },
    {
        "text": "is it possible to be allergic to",
        "answer": "water",
        "luckyGuess": "earth"
    },
    {
        "text": "why do people enjoy",
        "answer": "dancing",
        "luckyGuess": "singing"
    },
    {
        "text": "can exercise improve your",
        "answer": "mood",
        "luckyGuess": "zest"
    },
    {
        "text": "when pregnant, can i eat",
        "answer": "sushi",
        "luckyGuess": "spicy"
    },
    {
        "text": "why do socks always",
        "answer": "vanish",
        "luckyGuess": "shrink"
    },
    {
        "text": "is it possible to train a fish to",
        "answer": "flip",
        "luckyGuess": "sing"
    },
    {
        "text": "why do we never see baby",
        "answer": "pigeons",
        "luckyGuess": "rabbits"
    },
    {
        "text": "my friend has a pet",
        "answer": "rock",
        "luckyGuess": "worm"
    },
    {
        "text": "can plants get",
        "answer": "lonely",
        "luckyGuess": "grumpy"
    },
    {
        "text": "is chocolate a good",
        "answer": "gift",
        "luckyGuess": "meal"
    },
    {
        "text": "is it possible to become friends with a",
        "answer": "robot",
        "luckyGuess": "ghost"
    },
    {
        "text": "why are dogs so",
        "answer": "loyal",
        "luckyGuess": "space"
    },
    {
        "text": "does the Easter bunny eat",
        "answer": "carrots",
        "luckyGuess": "marzipan"
    },
    {
        "text": "My mom told me the Easter bunny likes to relax with a",
        "answer": "shot",
        "luckyGuess": "beer"
    },
    {
        "text": "does the Easter bunny have",
        "answer": "helpers",
        "luckyGuess": "friends"
    },
    {
        "text": "can eating too much Easter chocolate cause",
        "answer": "death",
        "luckyGuess": "pains"
    },
    {
        "text": "The Easter bunny brought my step dad",
        "answer": "money",
        "luckyGuess": "booze"
    },
    {
        "text": "why are some people born with",
        "answer": "tails",
        "luckyGuess": "wings"
    },
    {
        "text": "is it harmful to think too much about",
        "answer": "unicorns",
        "luckyGuess": "unicorns"
    },
    {
        "text": "how do animals decide who is the",
        "answer": "alpha",
        "luckyGuess": "queen"
    },
    {
        "text": "can you learn to swim in your",
        "answer": "bathtub",
        "luckyGuess": "kitchen"
    },
    {
        "text": "do plants enjoy listening to",
        "answer": "jazz",
        "luckyGuess": "rock"
    },
    {
        "text": "can you teach a robot to",
        "answer": "love",
        "luckyGuess": "hate"
    },
    {
        "text": "why do some humans love to",
        "answer": "argue",
        "luckyGuess": "dance"
    },
    {
        "text": "can trees communicate through",
        "answer": "roots",
        "luckyGuess": "email"
    },
    {
        "text": "is it possible to hear",
        "answer": "colors",
        "luckyGuess": "ghosts"
    },
    {
        "text": "how do birds learn to",
        "answer": "migrate",
        "luckyGuess": "zombies"
    },
    {
        "text": "can humans have extra",
        "answer": "fingers",
        "luckyGuess": "example"
    },
    {
        "text": "why are some people naturally",
        "answer": "strong",
        "luckyGuess": "skinny"
    },
    {
        "text": "do dreams have",
        "answer": "sound",
        "luckyGuess": "taste"
    },
    {
        "text": "what happens if you never",
        "answer": "floss",
        "luckyGuess": "sleep"
    },
    {
        "text": "can laughter really",
        "answer": "heal",
        "luckyGuess": "kill"
    },
    {
        "text": "is it true that owls are",
        "answer": "wise",
        "luckyGuess": "rare"
    },
    {
        "text": "can animals predict",
        "answer": "tsunamis",
        "luckyGuess": "weathers"
    },
    {
        "text": "why are some memories",
        "answer": "false",
        "luckyGuess": "happy"
    },
    {
        "text": "can fish",
        "answer": "smell",
        "luckyGuess": "taste"
    },
    {
        "text": "why are cats",
        "answer": "mean",
        "luckyGuess": "cute"
    },
    {
        "text": "do plants get",
        "answer": "lonely",
        "luckyGuess": "boreds"
    },
    {
        "text": "do birds have",
        "answer": "hands",
        "luckyGuess": "gills"
    },
    {
        "text": "can robots learn to",
        "answer": "think",
        "luckyGuess": "paint"
    },
    {
        "text": "what is out",
        "answer": "there",
        "luckyGuess": "blink"
    },
    {
        "text": "i don't really get",
        "answer": "music",
        "luckyGuess": "dream"
    },
    {
        "text": "i had a a dream about",
        "answer": "falling",
        "luckyGuess": "jumping"
    },
    {
        "text": "why do people get obsessed with",
        "answer": "yoga",
        "luckyGuess": "book"
    },
    {
        "text": "why do we feel",
        "answer": "cold",
        "luckyGuess": "bold"
    },
    {
        "text": "how high up until i",
        "answer": "explode",
        "luckyGuess": "unravel"
    },
    {
        "text": "is it possible to live without",
        "answer": "friends",
        "luckyGuess": "familys"
    },
    {
        "text": "is it healthy to skip",
        "answer": "meals",
        "luckyGuess": "sleep"
    },
    {
        "text": "is it too late to learn",
        "answer": "coding",
        "luckyGuess": "online"
    },
    {
        "text": "my mom said that i have",
        "answer": "issues",
        "luckyGuess": "kisses"
    },
    {
        "text": "why do cats love",
        "answer": "lasers",
        "luckyGuess": "videos"
    },
    {
        "text": "at the end of time, will there be",
        "answer": "peace",
        "luckyGuess": "chaos"
    },
    {
        "text": "i want to be a",
        "answer": "youtuber",
        "luckyGuess": "engineer"
    },
    {
        "text": "why are the british so",
        "answer": "polite",
        "luckyGuess": "zombie"
    },
    {
        "text": "do pirates",
        "answer": "exist",
        "luckyGuess": "dance"
    },
    {
        "text": "who is the king of",
        "answer": "rock",
        "luckyGuess": "yoga"
    },
    {
        "text": "moustache",
        "answer": "styles",
        "luckyGuess": "trends"
    },
    {
        "text": "gold",
        "answer": "prices",
        "luckyGuess": "silver"
    },
    {
        "text": "why do fighters have weird",
        "answer": "ears",
        "luckyGuess": "hats"
    },
    {
        "text": "beauty pageants for",
        "answer": "dogs",
        "luckyGuess": "cats"
    },
    {
        "text": "why is america so",
        "answer": "great",
        "luckyGuess": "happy"
    },
    {
        "text": "in australia, do they have",
        "answer": "snow",
        "luckyGuess": "guns"
    },
    {
        "text": "can two rocks make",
        "answer": "fire",
        "luckyGuess": "love"
    },
    {
        "text": "when can i swim after a",
        "answer": "tattoo",
        "luckyGuess": "shower"
    },
    {
        "text": "why do we need",
        "answer": "laws",
        "luckyGuess": "rule"
    },
    {
        "text": "why is my campus so",
        "answer": "large",
        "luckyGuess": "happy"
    },
    {
        "text": "why does my water taste",
        "answer": "weird",
        "luckyGuess": "funny"
    },
    {
        "text": "in disneyworld, do they have",
        "answer": "rides",
        "luckyGuess": "shops"
    },
    {
        "text": "why are chips so",
        "answer": "salty",
        "luckyGuess": "tasty"
    },
    {
        "text": "why is it so hard to learn",
        "answer": "chinese",
        "luckyGuess": "algebra"
    },
    {
        "text": "i am so thankful for my",
        "answer": "brother",
        "luckyGuess": "friends"
    },
    {
        "text": "in college, should i",
        "answer": "party",
        "luckyGuess": "study"
    },
    {
        "text": "why is my car",
        "answer": "noisy",
        "luckyGuess": "shiny"
    },
    {
        "text": "can you swim in the",
        "answer": "ocean",
        "luckyGuess": "river"
    },
    {
        "text": "why does my phone keep",
        "answer": "crashing",
        "luckyGuess": "freezing"
    },
    {
        "text": "what do you do on a",
        "answer": "weekend",
        "luckyGuess": "holiday"
    },
    {
        "text": "why does it rain so much in",
        "answer": "spring",
        "luckyGuess": "winter"
    },
    {
        "text": "what do you call a baby",
        "answer": "shark",
        "luckyGuess": "puppy"
    },
    {
        "text": "why is my pizza so",
        "answer": "greasy",
        "luckyGuess": "cheesy"
    },
    {
        "text": "where can i find a",
        "answer": "charger",
        "luckyGuess": "battery"
    },
    {
        "text": "how do you treat a",
        "answer": "blister",
        "luckyGuess": "disease"
    },
    {
        "text": "where can i rent a",
        "answer": "costume",
        "luckyGuess": "freezer"
    },
    {
        "text": "where do i get a",
        "answer": "trim",
        "luckyGuess": "suit"
    },
    {
        "text": "how do i create a",
        "answer": "backup",
        "luckyGuess": "script"
    },
    {
        "text": "how do you reset a",
        "answer": "username",
        "luckyGuess": "password"
    },
    {
        "text": "what do you use to",
        "answer": "measure",
        "luckyGuess": "analyze"
    },
    {
        "text": "what can you cook with",
        "answer": "ginger",
        "luckyGuess": "pepper"
    },
    {
        "text": "why is it so hard to",
        "answer": "climb",
        "luckyGuess": "learn"
    },
    {
        "text": "how do i tame a",
        "answer": "rabbit",
        "luckyGuess": "parrot"
    },
    {
        "text": "at the end of a rainbow, is there",
        "answer": "coins",
        "luckyGuess": "money"
    },
    {
        "text": "in a cave, will we find",
        "answer": "treasure",
        "luckyGuess": "monsters"
    },
    {
        "text": "how do you solve a",
        "answer": "puzzle",
        "luckyGuess": "riddle"
    },
    {
        "text": "what do you put on a",
        "answer": "burger",
        "luckyGuess": "hotdog"
    },
    {
        "text": "how do i set up a",
        "answer": "hotspot",
        "luckyGuess": "network"
    },
    {
        "text": "what do you bring to a",
        "answer": "potluck",
        "luckyGuess": "concert"
    },
    {
        "text": "why does my dog",
        "answer": "chew",
        "luckyGuess": "bark"
    },
    {
        "text": "is the secret to a happy life",
        "answer": "balance",
        "luckyGuess": "harmony"
    },
    {
        "text": "is the best food for a picnic a",
        "answer": "sandwich",
        "luckyGuess": "smoothie"
    },
    {
        "text": "at the beach, should i go",
        "answer": "swimming",
        "luckyGuess": "sunbathe"
    },
    {
        "text": "when i want to relax, should i",
        "answer": "meditate",
        "luckyGuess": "exercise"
    },
    {
        "text": "what is the best food for",
        "answer": "camping",
        "luckyGuess": "working"
    },
    {
        "text": "is the perfect pizza topping",
        "answer": "sausage",
        "luckyGuess": "chicken"
    },
    {
        "text": "which animal is the",
        "answer": "fastest",
        "luckyGuess": "kindest"
    },
    {
        "text": "for a road trip, should i pack",
        "answer": "drinks",
        "luckyGuess": "pillow"
    },
    {
        "text": "why do i have to",
        "answer": "study",
        "luckyGuess": "learn"
    },
    {
        "text": "my mom always tells me to",
        "answer": "clean",
        "luckyGuess": "study"
    },
    {
        "text": "should i start the day with a",
        "answer": "walk",
        "luckyGuess": "game"
    },
    {
        "text": "the best way to cool off is with a",
        "answer": "smoothie",
        "luckyGuess": "popsicle"
    },
    {
        "text": "for a quick snack, should i eat a",
        "answer": "banana",
        "luckyGuess": "cookie"
    },
    {
        "text": "is the key to success",
        "answer": "practice",
        "luckyGuess": "patience"
    },
    {
        "text": "can you plant",
        "answer": "carrots",
        "luckyGuess": "flowers"
    },
    {
        "text": "when writing, should i use a",
        "answer": "pencil",
        "luckyGuess": "marker"
    },
    {
        "text": "is a good breakfast",
        "answer": "oatmeal",
        "luckyGuess": "sausage"
    },
    {
        "text": "on the weekend, is it better to",
        "answer": "relax",
        "luckyGuess": "party"
    },
    {
        "text": "for exercise, is it good to",
        "answer": "dance",
        "luckyGuess": "track"
    },
    {
        "text": "is the best vacation spot a",
        "answer": "resort",
        "luckyGuess": "island"
    },
    {
        "text": "for a fun game night, should i play",
        "answer": "charades",
        "luckyGuess": "dominoes"
    },
    {
        "text": "for a creative outlet, should i",
        "answer": "write",
        "luckyGuess": "paint"
    },
    {
        "text": "on weekends, should i",
        "answer": "travel",
        "luckyGuess": "stroll"
    },
    {
        "text": "why do so many people love",
        "answer": "soccer",
        "luckyGuess": "tennis"
    },
    {
        "text": "is the best music",
        "answer": "jazz",
        "luckyGuess": "folk"
    },
    {
        "text": "in the fall should i wear",
        "answer": "cardigan",
        "luckyGuess": "sweaters"
    },
    {
        "text": "is the best go-to drink",
        "answer": "coffee",
        "luckyGuess": "herbal"
    },
    {
        "text": "i wanto learn how to",
        "answer": "sail",
        "luckyGuess": "camp"
    },
    {
        "text": "on a snowy day, is it best to",
        "answer": "sleep",
        "luckyGuess": "skate"
    },
    {
        "text": "should i read",
        "answer": "mystery",
        "luckyGuess": "fantasy"
    },
    {
        "text": "I like plants, should i get a(n)",
        "answer": "orchid",
        "luckyGuess": "cactus"
    },
    {
        "text": "every day i eat",
        "answer": "chicken",
        "luckyGuess": "seafood"
    },
    {
        "text": "is a good pet for a kid a",
        "answer": "ferret",
        "luckyGuess": "lizard"
    },
    {
        "text": "is the best summer activity",
        "answer": "camping",
        "luckyGuess": "tanning"
    },
    {
        "text": "to stay organized, should i use a",
        "answer": "planner",
        "luckyGuess": "journal"
    },
    {
        "text": "is the best summer drink a",
        "answer": "lemonade",
        "luckyGuess": "mocktail"
    },
    {
        "text": "as a comfort food, should i eat",
        "answer": "lasagna",
        "luckyGuess": "risotto"
    },
    {
        "text": "as a houseplant, should i buy a",
        "answer": "cactus",
        "luckyGuess": "orchid"
    },
    {
        "text": "is the best fruit for pies",
        "answer": "berry",
        "luckyGuess": "apple"
    },
    {
        "text": "will australia ever be",
        "answer": "invaded",
        "luckyGuess": "flooded"
    },
    {
        "text": "is human or not",
        "answer": "safe",
        "luckyGuess": "real"
    },
    {
        "text": "will i die if i eat",
        "answer": "mold",
        "luckyGuess": "glue"
    },
    {
        "text": "is human skin",
        "answer": "leather",
        "luckyGuess": "tougher"
    },
    {
        "text": "why is mario so",
        "answer": "short",
        "luckyGuess": "quick"
    },
    {
        "text": "why is mr. beast",
        "answer": "famous",
        "luckyGuess": "giving"
    },
    {
        "text": "why are americans so",
        "answer": "tall",
        "luckyGuess": "bold"
    },
    {
        "text": "what happens if I drink too much",
        "answer": "coffee",
        "luckyGuess": "liquor"
    },
    {
        "text": "can I get addicted to",
        "answer": "vape",
        "luckyGuess": "wine"
    },
    {
        "text": "do women like",
        "answer": "beards",
        "luckyGuess": "flirts"
    },
    {
        "text": "why does it hurt when i",
        "answer": "breathe",
        "luckyGuess": "swallow"
    },
    {
        "text": "is zelda a",
        "answer": "dragon",
        "luckyGuess": "prince"
    },
    {
        "text": "do ghosts have",
        "answer": "teeth",
        "luckyGuess": "souls"
    },
    {
        "text": "is it true that vegans eat",
        "answer": "fish",
        "luckyGuess": "meat"
    },
    {
        "text": "why does cheese have",
        "answer": "holes",
        "luckyGuess": "sweat"
    },
    {
        "text": "did harry potter ever",
        "answer": "cry",
        "luckyGuess": "die"
    },
    {
        "text": "does will smith have an",
        "answer": "oscar",
        "luckyGuess": "child"
    },
    {
        "text": "why do cannibals wear",
        "answer": "masks",
        "luckyGuess": "pants"
    },
    {
        "text": "will i die if i drink",
        "answer": "shampoo",
        "luckyGuess": "whiskey"
    },
    {
        "text": "why is chocolate so",
        "answer": "sweet",
        "luckyGuess": "yummy"
    },
    {
        "text": "can donkeys",
        "answer": "laugh",
        "luckyGuess": "speak"
    },
    {
        "text": "when i jump i",
        "answer": "pee",
        "luckyGuess": "fly"
    },
    {
        "text": "why does bitcoin",
        "answer": "exist",
        "luckyGuess": "halve"
    },
    {
        "text": "i'm allergic to",
        "answer": "water",
        "luckyGuess": "dairy"
    },
    {
        "text": "Do i need a",
        "answer": "blender",
        "luckyGuess": "spatula"
    },
    {
        "text": "is there a god of",
        "answer": "time",
        "luckyGuess": "fire"
    },
    {
        "text": "as a major, should i study",
        "answer": "philosophy",
        "luckyGuess": "literature"
    },
    {
        "text": "should i explore a city by",
        "answer": "bicycle",
        "luckyGuess": "walking"
    },
    {
        "text": "my tiktok is in",
        "answer": "spanish",
        "luckyGuess": "russian"
    },
    {
        "text": "can zombies",
        "answer": "swim",
        "luckyGuess": "jump"
    },
    {
        "text": "why is wordle so",
        "answer": "popular",
        "luckyGuess": "complex"
    },
    {
        "text": "is it fun to be a",
        "answer": "girl",
        "luckyGuess": "pony"
    },
    {
        "text": "can you create a black hole with a",
        "answer": "photo",
        "luckyGuess": "horse"
    },
    {
        "text": "why do cats",
        "answer": "meow",
        "luckyGuess": "purr"
    },
    {
        "text": "is giga chad",
        "answer": "alive",
        "luckyGuess": "happy"
    },
    {
        "text": "do trees feel",
        "answer": "emotion",
        "luckyGuess": "sadness"
    },
    {
        "text": "is it okay to date your",
        "answer": "boss",
        "luckyGuess": "wife"
    },
    {
        "text": "do robots have",
        "answer": "feelings",
        "luckyGuess": "emotions"
    },
    {
        "text": "i broke my",
        "answer": "phone",
        "luckyGuess": "heart"
    },
    {
        "text": "should i read a book about",
        "answer": "astronomy",
        "luckyGuess": "mythology"
    },
    {
        "text": "how to deal with",
        "answer": "stress",
        "luckyGuess": "people"
    },
    {
        "text": "is it possible to run out of",
        "answer": "tears",
        "luckyGuess": "water"
    },
    {
        "text": "do fish ever get",
        "answer": "tired",
        "luckyGuess": "bored"
    },
    {
        "text": "can a cat and dog",
        "answer": "breed",
        "luckyGuess": "dance"
    },
    {
        "text": "why do ghosts say",
        "answer": "boo",
        "luckyGuess": "baa"
    },
    {
        "text": "what if google was a",
        "answer": "human",
        "luckyGuess": "robot"
    },
    {
        "text": "can you see your own",
        "answer": "aura",
        "luckyGuess": "nose"
    },
    {
        "text": "do you really need a",
        "answer": "snorkel",
        "luckyGuess": "wetsuit"
    },
    {
        "text": "are video games bad for",
        "answer": "eyes",
        "luckyGuess": "kids"
    },
    {
        "text": "is there a world record for",
        "answer": "sleeping",
        "luckyGuess": "twerking"
    },
    {
        "text": "how to fake",
        "answer": "tan",
        "luckyGuess": "cry"
    },
    {
        "text": "are plants actually",
        "answer": "alive",
        "luckyGuess": "green"
    },
    {
        "text": "never put a sock in a",
        "answer": "toaster",
        "luckyGuess": "blender"
    },
    {
        "text": "why do mushrooms",
        "answer": "glow",
        "luckyGuess": "grow"
    },
    {
        "text": "is it legal to kill a",
        "answer": "bear",
        "luckyGuess": "duck"
    },
    {
        "text": "is mark zuckerberg a",
        "answer": "cyborg",
        "luckyGuess": "lizard"
    },
    {
        "text": "why do reindeer have",
        "answer": "antlers",
        "luckyGuess": "goodies"
    },
    {
        "text": "best city for",
        "answer": "living",
        "luckyGuess": "expats"
    },
    {
        "text": "why is the world so",
        "answer": "cruel",
        "luckyGuess": "harsh"
    },
    {
        "text": "oh no i dropped my",
        "answer": "phone",
        "luckyGuess": "phone"
    },
    {
        "text": "why don't men",
        "answer": "listen",
        "luckyGuess": "change"
    },
    {
        "text": "how to escape from",
        "answer": "school",
        "luckyGuess": "myself"
    },
    {
        "text": "does santa have an",
        "answer": "email",
        "luckyGuess": "phone"
    },
    {
        "text": "when will humans",
        "answer": "evolve",
        "luckyGuess": "return"
    },
    {
        "text": "did facebook buy",
        "answer": "tiktok",
        "luckyGuess": "oculus"
    },
    {
        "text": "is it safe to",
        "answer": "travel",
        "luckyGuess": "invest"
    },
    {
        "text": "is bing a",
        "answer": "virus",
        "luckyGuess": "guide"
    },
    {
        "text": "does santa visit",
        "answer": "heaven",
        "luckyGuess": "adults"
    },
    {
        "text": "is it scary to give",
        "answer": "birth",
        "luckyGuess": "blood"
    },
    {
        "text": "do cows have",
        "answer": "horns",
        "luckyGuess": "taste"
    },
    {
        "text": "is it weird to love",
        "answer": "school",
        "luckyGuess": "animal"
    },
    {
        "text": "does sleep really help you",
        "answer": "grow",
        "luckyGuess": "heal"
    },
    {
        "text": "whats the science behind",
        "answer": "farming",
        "luckyGuess": "cycling"
    },
    {
        "text": "how to write a",
        "answer": "resume",
        "luckyGuess": "report"
    },
    {
        "text": "why is space so",
        "answer": "empty",
        "luckyGuess": "scary"
    },
    {
        "text": "can you fly with a",
        "answer": "hernia",
        "luckyGuess": "injury"
    },
    {
        "text": "what to do when",
        "answer": "angry",
        "luckyGuess": "awake"
    },
    {
        "text": "can you drink your own",
        "answer": "blood",
        "luckyGuess": "sweat"
    },
    {
        "text": "is it bad to eat before",
        "answer": "gym",
        "luckyGuess": "bed"
    },
    {
        "text": "exploding",
        "answer": "watermelon",
        "luckyGuess": "windjammer"
    },
    {
        "text": "effective methods for",
        "answer": "study",
        "luckyGuess": "focus"
    },
    {
        "text": "should i join a class for",
        "answer": "pottery",
        "luckyGuess": "cooking"
    },
    {
        "text": "how to trick a",
        "answer": "scammer",
        "luckyGuess": "spammer"
    },
    {
        "text": "should i learn to play the",
        "answer": "ukelele",
        "luckyGuess": "trumpet"
    },
    {
        "text": "is there life on",
        "answer": "venus",
        "luckyGuess": "plant"
    },
    {
        "text": "my dog is so",
        "answer": "needy",
        "luckyGuess": "happy"
    },
    {
        "text": "can you marry your",
        "answer": "cousin",
        "luckyGuess": "sister"
    },
    {
        "text": "why are spiders so",
        "answer": "smart",
        "luckyGuess": "scary"
    },
    {
        "text": "did elon make twitter",
        "answer": "worse",
        "luckyGuess": "money"
    },
    {
        "text": "why are the kardashians so",
        "answer": "dramatic",
        "luckyGuess": "annoying"
    },
    {
        "text": "should i paint the walls",
        "answer": "beige",
        "luckyGuess": "green"
    },
    {
        "text": "why is the sky so",
        "answer": "high",
        "luckyGuess": "hazy"
    },
    {
        "text": "is it safe to eat raw",
        "answer": "bacon",
        "luckyGuess": "steak"
    },
    {
        "text": "is it normal to have",
        "answer": "dandruff",
        "luckyGuess": "feathers"
    },
    {
        "text": "can i use my phone while",
        "answer": "charging",
        "luckyGuess": "updating"
    },
    {
        "text": "best christmas",
        "answer": "movies",
        "luckyGuess": "carols"
    },
    {
        "text": "why is honey so",
        "answer": "sticky",
        "luckyGuess": "useful"
    },
    {
        "text": "does bill gates own a",
        "answer": "plane",
        "luckyGuess": "yacht"
    },
    {
        "text": "best christmas gift for my",
        "answer": "boss",
        "luckyGuess": "wife"
    },
    {
        "text": "is it illegal to drive in",
        "answer": "heels",
        "luckyGuess": "socks"
    },
    {
        "text": "are rainbows",
        "answer": "round",
        "luckyGuess": "light"
    },
    {
        "text": "why do humans have",
        "answer": "chins",
        "luckyGuess": "nails"
    },
    {
        "text": "is it possible to live on",
        "answer": "mars",
        "luckyGuess": "moon"
    },
    {
        "text": "is it possible for humans to have",
        "answer": "wings",
        "luckyGuess": "gills"
    },
    {
        "text": "should i write a story about a",
        "answer": "dragon",
        "luckyGuess": "pirate"
    },
    {
        "text": "as a gift, should i buy",
        "answer": "jewelry",
        "luckyGuess": "artwork"
    },
    {
        "text": "can the government control the",
        "answer": "weather",
        "luckyGuess": "dollar"
    },
    {
        "text": "is it okay to eat raw",
        "answer": "bacon",
        "luckyGuess": "pasta"
    },
    {
        "text": "for a dance, should i learn the",
        "answer": "waltz",
        "luckyGuess": "tango"
    },
    {
        "text": "is the world",
        "answer": "flat",
        "luckyGuess": "tiny"
    },
    {
        "text": "can i use string to",
        "answer": "floss",
        "luckyGuess": "braid"
    },
    {
        "text": "why are oceans",
        "answer": "salty",
        "luckyGuess": "empty"
    },
    {
        "text": "what is the most horrible",
        "answer": "smell",
        "luckyGuess": "sight"
    },
    {
        "text": "my mother is so",
        "answer": "annoying",
        "luckyGuess": "negative"
    },
    {
        "text": "can humans get",
        "answer": "fleas",
        "luckyGuess": "wings"
    },
    {
        "text": "how to start a",
        "answer": "essay",
        "luckyGuess": "story"
    },
    {
        "text": "tips for growing",
        "answer": "plants",
        "luckyGuess": "beards"
    },
    {
        "text": "can we live without a",
        "answer": "brain",
        "luckyGuess": "liver"
    },
    {
        "text": "what happens if you give a mouse a",
        "answer": "cookie",
        "luckyGuess": "cheese"
    },
    {
        "text": "how to draw a",
        "answer": "dragon",
        "luckyGuess": "donkey"
    },
    {
        "text": "is it legal to keep a pet",
        "answer": "otter",
        "luckyGuess": "eagle"
    },
    {
        "text": "is it wrong to not like",
        "answer": "babies",
        "luckyGuess": "family"
    },
    {
        "text": "can you catch a",
        "answer": "bullet",
        "luckyGuess": "rabbit"
    },
    {
        "text": "fifa is",
        "answer": "rigged",
        "luckyGuess": "ending"
    },
    {
        "text": "are humans the only animals with",
        "answer": "thumbs",
        "luckyGuess": "rights"
    },
    {
        "text": "why are dogs so",
        "answer": "loyal",
        "luckyGuess": "happy"
    },
    {
        "text": "tips for keeping a pet",
        "answer": "snail",
        "luckyGuess": "mouse"
    },
    {
        "text": "do masks cause",
        "answer": "wrinkles",
        "luckyGuess": "headache"
    },
    {
        "text": "does the internet make you",
        "answer": "smarter",
        "luckyGuess": "quicker"
    },
    {
        "text": "why is water so",
        "answer": "heavy",
        "luckyGuess": "tasty"
    },
    {
        "text": "can you make money from",
        "answer": "youtube",
        "luckyGuess": "spotify"
    },
    {
        "text": "do birds ever",
        "answer": "sleep",
        "luckyGuess": "fight"
    },
    {
        "text": "do mushrooms make you",
        "answer": "taller",
        "luckyGuess": "sicker"
    },
    {
        "text": "why do humans need",
        "answer": "oxygen",
        "luckyGuess": "energy"
    },
    {
        "text": "should i adopt a",
        "answer": "puppy",
        "luckyGuess": "snake"
    },
    {
        "text": "fastest way to get into",
        "answer": "ketosis",
        "luckyGuess": "fitness"
    },
    {
        "text": "what happens if we run out of",
        "answer": "money",
        "luckyGuess": "water"
    },
    {
        "text": "why can't i own a",
        "answer": "tank",
        "luckyGuess": "nuke"
    },
    {
        "text": "is it safe to kiss a",
        "answer": "frog",
        "luckyGuess": "baby"
    },
    {
        "text": "can santa bring",
        "answer": "pets",
        "luckyGuess": "dogs"
    },
    {
        "text": "why are cats so",
        "answer": "clean",
        "luckyGuess": "funny"
    },
    {
        "text": "can an ant lift an",
        "answer": "apple",
        "luckyGuess": "human"
    },
    {
        "text": "is it painful to give birth to a",
        "answer": "dog",
        "luckyGuess": "cat"
    },
    {
        "text": "what happens when you cross a",
        "answer": "border",
        "luckyGuess": "pisces"
    },
    {
        "text": "does music really calm",
        "answer": "anxiety",
        "luckyGuess": "emotion"
    },
    {
        "text": "are canadians",
        "answer": "american",
        "luckyGuess": "friendly"
    },
    {
        "text": "is there a secret",
        "answer": "emoji",
        "luckyGuess": "world"
    },
    {
        "text": "is it legal to eat a",
        "answer": "turtle",
        "luckyGuess": "person"
    },
    {
        "text": "are aliens",
        "answer": "coming",
        "luckyGuess": "demons"
    },
    {
        "text": "can you survive a fall from a",
        "answer": "plane",
        "luckyGuess": "cliff"
    },
    {
        "text": "can humans live without",
        "answer": "bees",
        "luckyGuess": "meat"
    },
    {
        "text": "why do people believe in",
        "answer": "aliens",
        "luckyGuess": "angels"
    },
    {
        "text": "can babies see",
        "answer": "angels",
        "luckyGuess": "colour"
    },
    {
        "text": "would you please give me",
        "answer": "advice",
        "luckyGuess": "credit"
    },
    {
        "text": "do penguins ever have",
        "answer": "twins",
        "luckyGuess": "colds"
    },
    {
        "text": "why are we addicted to",
        "answer": "sugar",
        "luckyGuess": "phone"
    },
    {
        "text": "is it possible to buy a",
        "answer": "country",
        "luckyGuess": "rockets"
    },
    {
        "text": "funny",
        "answer": "socks",
        "luckyGuess": "jokes"
    },
    {
        "text": "is it normal for a dog to",
        "answer": "snore",
        "luckyGuess": "vomit"
    },
    {
        "text": "why are cars so",
        "answer": "cheap",
        "luckyGuess": "quiet"
    },
    {
        "text": "what happens if i eat too much",
        "answer": "protein",
        "luckyGuess": "noodles"
    },
    {
        "text": "do stars ever",
        "answer": "explode",
        "luckyGuess": "twinkle"
    },
    {
        "text": "how hard is it to become a",
        "answer": "pilot",
        "luckyGuess": "actor"
    },
    {
        "text": "can a tesla be",
        "answer": "hacked",
        "luckyGuess": "stolen"
    },
    {
        "text": "dumb christmas",
        "answer": "gifts",
        "luckyGuess": "songs"
    },
    {
        "text": "how to learn how to",
        "answer": "crochet",
        "luckyGuess": "origami"
    },
    {
        "text": "is the moon really",
        "answer": "cheese",
        "luckyGuess": "planet"
    },
    {
        "text": "my partner thinks I'm",
        "answer": "boring",
        "luckyGuess": "stupid"
    },
    {
        "text": "can you sell your",
        "answer": "blood",
        "luckyGuess": "brain"
    },
    {
        "text": "what happens when you",
        "answer": "dream",
        "luckyGuess": "sleep"
    },
    {
        "text": "do santas elves get",
        "answer": "paid",
        "luckyGuess": "sick"
    },
    {
        "text": "why are polar bears so",
        "answer": "deadly",
        "luckyGuess": "strong"
    },
    {
        "text": "is it wise to trust a",
        "answer": "stranger",
        "luckyGuess": "computer"
    },
    {
        "text": "why is netflix so",
        "answer": "laggy",
        "luckyGuess": "quiet"
    },
    {
        "text": "crypto made me",
        "answer": "broke",
        "luckyGuess": "smart"
    },
    {
        "text": "should i consider for a pet a",
        "answer": "rabbit",
        "luckyGuess": "ferret"
    },
    {
        "text": "stupid",
        "answer": "jokes",
        "luckyGuess": "memes"
    },
    {
        "text": "can you control your",
        "answer": "dreams",
        "luckyGuess": "friend"
    },
    {
        "text": "why can't humans live",
        "answer": "longer",
        "luckyGuess": "deeper"
    },
    {
        "text": "my cat acts like a",
        "answer": "child",
        "luckyGuess": "bunny"
    },
    {
        "text": "does the earth really",
        "answer": "rotate",
        "luckyGuess": "wobble"
    },
    {
        "text": "documentary about",
        "answer": "history",
        "luckyGuess": "climate"
    },
    {
        "text": "will ai destroy",
        "answer": "humanity",
        "luckyGuess": "industry"
    },
    {
        "text": "how to fix a",
        "answer": "zipper",
        "luckyGuess": "button"
    },
    {
        "text": "is it possible to clone a",
        "answer": "human",
        "luckyGuess": "mouse"
    },
    {
        "text": "why are planets",
        "answer": "round",
        "luckyGuess": "quiet"
    },
    {
        "text": "is there a cure for",
        "answer": "cancer",
        "luckyGuess": "rabies"
    },
    {
        "text": "why are there",
        "answer": "seasons",
        "luckyGuess": "planets"
    },
    {
        "text": "is jeff bezos",
        "answer": "alive",
        "luckyGuess": "greek"
    },
    {
        "text": "why do parents",
        "answer": "fight",
        "luckyGuess": "argue"
    },
    {
        "text": "the importance of having",
        "answer": "friends",
        "luckyGuess": "savings"
    },
    {
        "text": "why does nintendo hate",
        "answer": "luigi",
        "luckyGuess": "wario"
    },
    {
        "text": "why do serial killers have",
        "answer": "fans",
        "luckyGuess": "eyes"
    },
    {
        "text": "do fish ever",
        "answer": "drown",
        "luckyGuess": "sleep"
    },
    {
        "text": "tiktok is",
        "answer": "cringe",
        "luckyGuess": "addict"
    },
    {
        "text": "does will smith have an",
        "answer": "oscar",
        "luckyGuess": "child"
    },
    {
        "text": "what happens if i eat too much",
        "answer": "protein",
        "luckyGuess": "noodles"
    },
    {
        "text": "why is wordle so",
        "answer": "popular",
        "luckyGuess": "complex"
    },
    {
        "text": "is bing a",
        "answer": "virus",
        "luckyGuess": "guide"
    },
    {
        "text": "are canadians",
        "answer": "american",
        "luckyGuess": "friendly"
    },
    {
        "text": "why is netflix so",
        "answer": "laggy",
        "luckyGuess": "quiet"
    },
    {
        "text": "why are the kardashians so",
        "answer": "dramatic",
        "luckyGuess": "annoying"
    },
    {
        "text": "fifa is",
        "answer": "rigged",
        "luckyGuess": "ending"
    },
    {
        "text": "my dog is so",
        "answer": "needy",
        "luckyGuess": "happy"
    },
    {
        "text": "will i die if i drink",
        "answer": "shampoo",
        "luckyGuess": "whiskey"
    },
    {
        "text": "why do cats",
        "answer": "meow",
        "luckyGuess": "purr"
    },
]